/* ---------------------------- Unified shadedcontent styles ---------------------------- */

.shadedcontent {
  /*border:thin green solid;*/
  width: 55%;
  left: 40%;
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  top: 75px;
  bottom: 75px;
  overflow-y: auto;
  font-family: Verdana;
  box-sizing: border-box;
  padding: 12px 17px;
}

.shadedcontent {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

.shadedcontent::-webkit-scrollbar {
  width: 8px;
}

.shadedcontent::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

.shadedcontent::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 4px;
  border: 3px solid var(--scrollbarBG);
}

.shaded-Biography {
  display: none;
}
.shaded-Diary {
  display: none;
}
.shaded-Gallery {
  visibility: hidden;
}
.shaded-Audio {
  display: none;
}
.shaded-Contact {
  display: none;
}
