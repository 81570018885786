/* ---------------------------- Title, Nameplate, and Nav styles ---------------------------- */

#page-title {
  position: relative;
  top: 0%;
  /*border:thin white solid;*/
  text-align: right;
  color: white;
  font-size: 3rem;
  padding-right: 5%;
  padding-top: 5px;
  height: 75px;
  /* display: none; */
  visibility: hidden;
}

#titleword {
  display: inline;
}

#nameplate {
  /*border:thick blue solid;*/
  position: absolute;
  bottom: 2%;
  left: 2%;
  color: white;
}

#nameplate-bigfont {
  font-size: 3rem;
}

#nameplate-smallfont {
  font-size: 2rem;
}

#nav {
  display: none;
  position: absolute;
  /*border:thin red solid;*/
  text-align: right;
  color: white;
  font-size: 1.5rem;
  padding-right: 5%;
  bottom: 0;
  left: 0;
  width: 95%;
  height: 55px;
}

.navlink {
  display: inline;
  margin-left: 2%;
  /*border:thin orange solid;*/
}

.navlink a {
  text-decoration: none;
}
