/* ---------------------------- Index Styles ---------------------------- */

.modal {
  border: 0;
  /* display: none; */
  position: absolute;
  z-index: 1000;
  width: 30px;
  height: 30px;
  margin: 0;
  right: 50%;
  top: 50%;
  background-color: rgba(44, 44, 44, 0);
}

.modal img {
  width: 100%;
  height: 100%;
}

#enter-cover {
  display: none;
  /* border:thin green solid; */
  right: 50px;
  bottom: 50px;
  width: 10%;
  position: absolute;
  color: white;
  font-size: 3rem;
  text-align: right;
}

#enter-cover a {
  text-decoration: none;
}
