/* ---------------------------- Gallery styles ---------------------------- */

#galleria {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: #000;
  /* height: 100px; */
}

/* ---------------------------- Audio styles ---------------------------- */

#audio-container {
  padding: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
}

.audio-reminder {
  position: relative;
  margin-top: 20px;
  font-size: 0.9rem;
  margin-left: auto;
  margin-right: auto;
  border: 0;
  padding: 0;
  overflow: auto;
  color: black;
  font-family: Verdana;
}

.jp-playlist {
  box-sizing: border-box;
  padding-left: 10px;
}

#twitter-feed-container {
  /* border: thin green solid; */
  box-sizing: border-box;
  padding: 1rem 1rem 0 0;
  width: 90%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
