/* ---------------------------------------------------------------------------
-------------------- MEDIA QUERIES -------------------------------------------
--------------------------------------------------------------------------- */

@media only screen and (max-aspect-ratio: 5/4), (min-aspect-ratio: 12/5), (max-width: 1000px) and (min-width: 431px) {
  /* -----------------------------------------
   ----------- HAMBURGER MENU -----------------
   ------------------------------------------*/

  #hamburger {
    position: absolute;
    right: 30px;
    top: 30px;
    margin-left: 10px;
    // display: inline-block;
    cursor: pointer;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 4px;
    border-radius: 2px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
  }

  /* Rotate first bar */
  .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-7px, 6px);
  }

  /* Fade out the second bar */
  .change .bar2 {
    opacity: 0;
  }

  /* Rotate last bar */
  .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-7px, -8px);
  }

  /* -------------------------------------------------------------------------
   ------------------------------- GENERAL STYLES -----------------------------
   ------------------------------------------------------------------------- */

  #container {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  #nameplate {
    top: 20px;
    left: 20px;
    z-index: -10;
  }

  #enter-cover ~ #nameplate {
    z-index: 2;
  }

  #nameplate-bigfont {
    font-size: 2rem;
  }

  #nameplate-smallfont {
    font-size: 1rem;
  }

  #page-title {
    padding-top: 27px;
    padding-right: 80px;
    font-size: 2rem;
  }

  #nav {
    position: relative;
    height: 18px;
    top: 8px;
    background-color: #888888;
    padding-top: 20px;
    padding-left: 5%;
    padding-bottom: 20px;
    text-align: center;
    width: 90%;
    z-index: -5;
    font-size: 1rem;
    box-shadow: inset 0px 7px 5px -5px #333333, inset 0px -7px 5px -5px #333333;
  }

  .Biographymain {
    background-position: 55% 100px;
    background-size: 120%;
  }

  .Diarymain {
    background-position: 50% 100px;
    background-size: 200%;
  }

  .Gallerymain {
    background-position: 70% 100px;
    background-size: 140%;
  }

  .Audiomain {
    background-position: 40% 100px;
    background-size: 120%;
  }

  .Contactmain {
    background-position: 100% 100px;
    background-size: 120%;
  }

  .shadedcontent {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    margin-bottom: 3%;
    left: 0;
    top: 270px;
    bottom: 0;
    height: fit-content;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    padding-bottom: 30px;
    box-shadow: 5px 5px 15px 5px #1c1c1c;
  }

  #longbiog p:nth-child(2) {
    margin-top: 15px;
  }

  #main {
    background-position: 40% 100px;
    background-size: cover;
  }

  #main.short-if-mobile {
    height: 250px !important;
  }

  #enter-cover {
    font-size: 2rem;
  }

  #galleria {
    position: relative;
    top: 10px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
  }

  /* -------------------------------------------------------------
   ----------------- HERO STYLES ----------------------------------
   --------------------------------------------------------------*/

  .mobilehero {
    display: block;
    overflow: hidden;
    height: 150px;
    position: relative;
    top: 0px;
  }

  .mobilehero img {
    position: relative;
    width: 100%;
    min-width: 700px;
  }

  .mobilehero.subhomehero {
    display: none;
  }

  /* --------------------- HAMBURGEROPEN AND HAMBURGERCLOSED STYLES ---------------------*/

  #nav.hamburgeropen {
    z-index: 5;
    transition: z-index;
    transition-delay: 0.5s;
  }

  #nav.hamburgerclosed {
    z-index: -5;
  }

  #main.hamburgeropen {
    background-position: 40% 168px;
    transition: background-position 0.5s;
  }

  #main.hamburgerclosed {
    background-position: 40% 100px;
    transition: background-position 0.5s;
  }
}

@media only screen and (max-width: 430px) {
  html {
    font-size: 10pt;
  }

  #titleword {
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 255px;
  }

  .shadedcontent {
    top: 300px;
  }
}
