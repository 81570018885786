#outside-wrapper {
  width: 90%;
  left: 5%;
  position: relative;
}

/* -------------------------------- Search styles -------------------------------- */

.search {
  padding: 0px;
}

.pastfilternone,
.futurefilternone {
  cursor: pointer;
}

#future-search-wrapper,
.past-search-wrapper {
  padding: 20px 0 30px 0;
  font-size: 1rem;
}

#future-search,
.past-search {
  width: 40%;
}

.showall {
  padding-left: 5px;
}

/* -------------------------------- accordionButton styles -------------------------------- */

.accordionButton-level1,
.accordionButton-level2,
.accordionButton-level3 {
  float: left;
  _float: none; /* Float works in all browsers but IE6 */
  background: none;
  cursor: pointer;
  /*border-left:1px solid #FFFFFF;*/
  z-index: 10;
}

.accordionButton-level1 {
  width: 100%;
  border-bottom: 1px solid #ffffff;
}

.accordionButton-level2,
.accordionButton-level3 {
  width: 100%;
  border-bottom: 0;
}

p.accordionButtontext {
  margin-top: 2px;
  margin-bottom: 3px;
  font-size: 1rem;
  font-weight: bold;
  z-index: 11;
}

/* -------------------------------- accordionContent styles -------------------------------- */

.accordionContent-level1-wrapper,
.accordionContent-level2-wrapper {
  width: 100%;
  left: 10%;
  padding-top: 0px;
}

.accordionContent-level1,
.accordionContent-level2,
.accordionContent-level3 {
  float: left;
  _float: none; /* Float works in all browsers but IE6 */
  background: none;
}

.accordionContent-level1 {
  width: 99%;
  padding-left: 5px;
}

.accordionContent-level2 {
  width: 99%;
  padding-left: 10px;
}

.accordionContent-level3 {
  width: 99%;
  padding-left: 15px;
}

/* -------------------------------- Indent styles -------------------------------- */

p.level1indent {
  padding-left: 5px;
}

p.level2indent {
  padding-left: 10px;
}

p.level3indent {
  padding-left: 15px;
}

/* ------------------------------- EVENT STYLES for DIARY ------------------------ */

.fade li {
  /*border:thin green solid;*/
  background-color: rgba(255, 255, 255, 0.3);
  margin-bottom: 30px;
  border-radius: 4px;
  padding: 1px 20px;
  box-shadow: 1px 1px 5px 2px #8e8e8e;
  margin-right: 10px;
}

li > hr {
  display: none;
  /*border:thick solid white;*/
}

.event-title,
.event-date-and-time,
.event-venue {
  margin-bottom: 10px;
}

.event-description {
  margin-bottom: 20px;
}

.event-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 20px;
}

.event-date-time,
.event-today {
  font-size: 1rem;
}

.event-venue {
  font-size: 0.9rem;
}

.event-description {
  font-size: 0.9rem;
  line-height: 1.2rem;
}

/***********************************************************************************************************************
 EXTRA STYLES ADDED FOR MOUSEOVER / ACTIVE EVENTS
************************************************************************************************************************/

.on {
  background: #b8b8b8;
}

.over {
  background: #cccccc;
}

@media only screen and (max-aspect-ratio: 5/4), (min-aspect-ratio: 12/5), (max-width: 1000px) and (min-width: 431px) {
  .accordionButton-level1,
  .accordionButton-level2,
  .accordionButton-level3 {
    height: 40px;
  }

  p.accordionButtontext {
    padding-top: 8px;
  }
}
