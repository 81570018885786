html {
  background-color: #2c2c2c;
  /*--scrollbarBG: #2c2c2c;*/
  --thumbBG: rgba(100, 100, 100, 0.5);
  font-size: 12pt;
}

body {
  background-color: #2c2c2c;
}

ul {
  list-style-type: none;
}

ul,
li {
  margin: 0px 0px 0px 0px;
  padding: 0px;
}

/* Don't display the hamburger menu when not on mobile view */
#hamburger {
  display: none;
}

img {
  border: none;
}

.tooltiplaunch {
  cursor: pointer;
}

span .tooltip:hover {
  cursor: pointer;
}

.oratorio {
  font-weight: bold;
  padding-left: 10px;
}

p a {
  color: black !important;
  text-decoration: underline solid transparent;
  transition: text-decoration 0.5s;
  font-weight: bold;

  &:hover {
    text-decoration: underline solid black;
  }
}

div a {
  color: white;
}

#main {
  box-shadow: 5px 5px 15px 5px #1c1c1c;
  /*background-color:grey;*/
  position: absolute;
  width: 100%;
  height: 100%;
  margin: auto;
  border: #2c2c2c;
  background-repeat: no-repeat;
}

#main.short-if-mobile {
  height: 100%;
}

.mobilehero {
  display: none;
}

@import "accordion";
@import "background-images";
@import "title-nameplate-nav";
@import "shaded-content";
@import "index-styles";
@import "biog-styles";
@import "gallery-audio-styles";
@import "media-queries";
