/* ---------------------------- Biog styles (also used on Audio for popups) ---------------------------- */

ul.bullets {
  list-style-type: circle;
  margin-left: 20px;
}

.biog {
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.biog li {
  margin: 10px 0px;
}

.biog li:first-child {
  margin-top: 20px;
}

.biog li:last-child {
  margin-bottom: 20px;
}

li.biog {
  margin: 20px 0;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 1px 20px;
  box-shadow: 1px 1px 5px 2px #8e8e8e;
  margin-right: 10px;
}

#longbiog p {
  line-height: 1.3rem;
  font-size: 0.9rem;
}

#longbiog p:nth-child(2) {
  margin-top: 5px;
}

.repertoiresection {
  border: 0;
  padding: 0;
  overflow: auto;
}

.x-position {
  position: absolute;
  width: 100%;
  top: -5px;
  text-align: right;
}

.popupclose {
  text-decoration: none;
}

.popupcontent {
  max-height: 400px;
  border: 0;
  margin-right: 20px;
  overflow: auto;
}

.popupcontent p {
  margin-top: 8px;
}
