/* ---------------------------- Background image source and positioning ---------------------------- */

.homemain,
.Homemain {
  background-image: url("../images/hometest.jpg");
  background-position: 40% 8%;
  background-size: 120%;
}

.subhomemain {
  background-image: url("../images/subhometest.jpg");
  background-position: 40% 10%;
  background-size: 120%;
}

.Biographymain {
  background-image: url("../images/biogtest.jpg");
  background-position: 55% 40%;
  background-size: 120%;
}

.Diarymain {
  background-image: url("../images/diarytest.jpg");
  background-position: 50% 0;
  background-size: 200%;
}

.Gallerymain {
  background-image: url("../images/gallerytest.jpg");
  background-position: 70% 10%;
  background-size: 140%;
}

.Audiomain {
  background-image: url("../images/audiotest.jpg");
  background-position: 40% 10%;
  background-size: 120%;
}

.Contactmain {
  background-image: url("../images/contacttest.jpg");
  background-position: 100% 10%;
  background-size: 120%;
}

#container {
  display: none;
  position: absolute;
  width: 90%;
  height: 90%;
  top: 5%;
  left: 5%;
  /*border:thin red solid;*/
}
